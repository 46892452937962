import { Link } from "gatsby"
import Img, { GatsbyImageProps } from "gatsby-image"
import React from "react"
import { relativeDateFormat } from "../utils/relativeDateFormat"
import "./PostEntry.scss"

export interface ICategory {
  title: string;
  slug: string;
}

export interface IPostEntry {
  image: GatsbyImageProps
  title: string
  category: ICategory[];
  date: string
  uri: string
}

const PostEntry: React.FunctionComponent<IPostEntry> = props => {
  const date = new Date(props.date);
  const formattedDate = relativeDateFormat(date);
  const formattedCategories = props.category.map(category => category.title).join(', ');
  return (
    <Link to={props.uri} className="post-entry__card">
      <Img {...props.image} className="post-entry__image" />
      <div className="post-entry__footer">
        {/* if category slug is unknown, display a generic one instead of empty string */}
        <p className="post-entry__category">
          {formattedCategories}
        </p>
        <h3 className="post-entry__title">{props.title}</h3>
        <p className="post-entry__date">{formattedDate}</p>
      </div>
    </Link>
  )
}

export default PostEntry