import { Link } from "gatsby"
import React from "react"
import { config } from "../../../config"
import axios from "axios"

import recompenseImage from "../../../images/index/whydeposit-recompense.svg"
import reduireImage from "../../../images/index/tupperware.svg"
import accederImage from "../../../images/shared/pin-electric-blue.svg"

import "./WhyDeposit.scss"

interface WasteCount {
  totalContents: number
}

const WHYDEPOSIT_ARGUMENTS = [
  {
    label: "accéder",
    text:
      "à un réseau de commerçants partenaires et rapportez vos emballages dans nos différents points de collecte",
    image: accederImage,
  },
  {
    label: "réduire",
    text:
      "son impact environnemental en empruntant gratuitement des emballages réutilisables",
    image: reduireImage,
  },
  {
    label: "être récompensé",
    text: "grâce à un système de points donnant accès à de nombreux cadeaux",
    image: recompenseImage,
  },
]

const WhyDeposit: React.FunctionComponent = () => {
  const [wasteCount, setWasteCount] = React.useState<WasteCount>()
  async function getData<T>(
    url: string,
    callback: (data: T | undefined) => void
  ) {
    try {
      let response = await axios.get<T>(url)
      callback(response.data)
    } catch (e) {
      callback(undefined)
    }
  }

  React.useEffect(() => {
    getData<WasteCount>(
      `${config.greengoUrl}/infos/contents?timestamp=${Date.now()}`,
      wasteCount => setWasteCount(wasteCount)
    )
  }, [])
  return (
    <section className="whydeposit section-padding background--pale-turquoise">
      <div className="container">
        <h2 className="heading heading--section-title">
          POURQUOI CHOISIR CONSIGNÉ POUR UN REPAS À EMPORTER&nbsp;?
        </h2>
        <h3 className="heading heading--section-subtitle">
          Un petit plat pour l’homme, un grand pas pour la planète
        </h3>
        <p>
          Avec l’explosion de la vente à emporter, on consomme en France{" "}
          <strong>25 emballages à usage unique par seconde.</strong>
        </p>
        <p>
          Le problème : la plupart des emballages alimentaires sont peu ou pas
          recyclés.
        </p>
        <div className="whydeposit__content-wrapper">
          <ul className="whydeposit__items">
            {WHYDEPOSIT_ARGUMENTS.map((argument, index) => (
              <li className="whydeposit__item" key={argument.label}>
                <div>
                  <div className="whydeposit__item--image-and-achievement">
                    <img
                      src={argument.image}
                      alt="collecteur"
                      className="whydeposit__item-picture"
                    />
                    {index === 1 && (
                      <div className="achievements__item--emballage">
                        <p className="paragraph paragraph--highlight">
                          <span className="count">
                            {wasteCount?.totalContents ?? "De nombreux"}
                          </span>{" "}
                          emballages évités !
                        </p>
                      </div>
                    )}
                  </div>
                  <h4>{argument.label}</h4>
                  <p>{argument.text}</p>
                </div>
              </li>
            ))}
          </ul>
          <Link to="/nous-trouver" className="link button button--color-pink">
            Trouver un restaurant
          </Link>
        </div>
      </div>
    </section>
  )
}

export default WhyDeposit
