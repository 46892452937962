import React from "react"

import Layout from "../components/Layout"
import AboveTheFold from "../components/pages/index/AboveTheFold"
import BlogPosts from "../components/pages/index/BlogPosts"
import HowItWorks from "../components/pages/index/HowItWorks"
import ForEveryActor from "../components/pages/index/ForEveryActor"
import Partnership from "../components/pages/index/Partnership"
import Testimonials from "../components/pages/index/Testimonials"
import WhyDeposit from "../components/pages/index/WhyDeposit"
import SEO from "../components/Seo"

const Index = () => (
  <Layout activePage="index">
    <SEO
      title="La Consigne GreenGo"
      description="La Consigne GreenGo est une app' mobile permettant d’emprunter facilement des emballages réutilisables et consignés dans tout type de structures : restaurants, cafétérias d’entreprises et grande distribution."
      keywords={["consigne", "emballage", "zéro", "déchets", "restauration"]}
    />
    <AboveTheFold />
    <Testimonials />
    <HowItWorks />
    <Partnership />
    <ForEveryActor />
    <WhyDeposit />
    <BlogPosts />
  </Layout>
)

export default Index
