import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import VerticalImageWithText from "../../VerticalImageWithText"

import "./Partnership.scss"

interface IPartnership {
  scrollToTop?: boolean
}

const Partnership: React.FunctionComponent<IPartnership> = props => {
  const images = useStaticQuery(graphql`
    query {
      accompagnement: file(name: { eq: "offre-accompagnement-sur-mesure" }) {
        childImageSharp {
          fluid(
            maxWidth: 500
            maxHeight: 400
            quality: 100
            srcSetBreakpoints: [350, 500, 1000]
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      emballages: file(name: { eq: "offre-emballages" }) {
        childImageSharp {
          fluid(
            maxWidth: 500
            maxHeight: 400
            quality: 100
            srcSetBreakpoints: [350, 500, 1000]
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lavage: file(name: { eq: "offre-lavage-externalise" }) {
        childImageSharp {
          fluid(
            maxWidth: 500
            maxHeight: 400
            quality: 100
            srcSetBreakpoints: [350, 500, 1000]
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      reseau: file(name: { eq: "offre-reseau-collecteurs" }) {
        childImageSharp {
          fluid(
            maxWidth: 500
            maxHeight: 400
            quality: 100
            srcSetBreakpoints: [350, 500, 1000]
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      service: file(name: { eq: "offre-service-digital" }) {
        childImageSharp {
          fluid(
            maxWidth: 500
            maxHeight: 400
            quality: 100
            srcSetBreakpoints: [350, 500, 1000]
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const features = [
    {
      title: "Interface digitale",
      text:
        "Notre solution s’intègre aux systèmes d'encaissement, aux plateformes de livraison et toute application existante.",
      image: images.service,
    },
    {
      title: "Points de collecte",
      text:
        "Grâce à la proximité entre les points de collecte du réseau, le retour des emballages n'a jamais été aussi simple.",
      image: images.reseau,
    },
    {
      title: "Emballages réutilisables",
      text:
        "Des emballages premium aux différents formats, pratiques et empilables sont disponibles dans notre catalogue.",
      image: images.emballages,
    },
    {
      title: "Lavage sur demande",
      text:
        "En option, vous pouvez bénificier d'un lavage externalisé en circuit court.",
      image: images.lavage,
    },
    {
      title: "Accompagnement",
      text:
        "Une équipe dédiée est à votre écoute, vous assiste dans vos besoins et s'adapte à vos contraintes.",
      image: images.accompagnement,
    },
  ]
  return (
    <section className="partnership section-padding">
      <div className="container">
        <h2 className="heading heading--section-title">Notre offre</h2>
        <h3 className="heading heading--section-subtitle">
          Un service 360 d'emballages réutilisables
        </h3>
        <div className="partnership__content-wrapper">
          <div className="partnership__description">
            <p>
              La Consigne GreenGo vous accompagne dans votre transition du
              jetable vers un modèle d’emballages réutilisables.
            </p>
          </div>
          <ul className="partnership__features">
            {features.map(feature => (
              <li key={feature.title}>
                <VerticalImageWithText {...feature} />
              </li>
            ))}
          </ul>
        </div>
        <div className="partnership__cta-wrapper">
          {props.scrollToTop ? (
            <p
              onClick={() => scrollTo(0, 0)}
              className="link button button--color-pink"
              aria-label="Naviguer vers le formulaire de contact"
            >
              Devenir partenaire
            </p>
          ) : (
            <Link
              to="/professionnels"
              className="link button button--color-pink"
            >
              Devenir partenaire
            </Link>
          )}
        </div>
      </div>
    </section>
  )
}

export default Partnership
