import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import "./ForEveryActor.scss"

const ForEveryActor: React.FunctionComponent = () => {
  const images = useStaticQuery(graphql`
    query {
      badgeCaisse: file(name: { eq: "badge-caisse" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      badgeEchoppe: file(name: { eq: "badge-echoppe" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      badgeImmeuble: file(name: { eq: "badge-immeuble" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      badgeLivraison: file(name: { eq: "badge-livraison" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  const ACTORS = [
    {
      label: "Restaurant indépendant",
      image: images.badgeEchoppe.childImageSharp,
      link: '/blog/paris-semballe-le-projet/'
    },
    {
      label: "Cafétéria d'entreprise",
      image: images.badgeImmeuble.childImageSharp,
      link: '/professionnels#entreprise'
    },
    {
      label: "Grande distribution",
      image: images.badgeCaisse.childImageSharp,
      link: '/professionnels#distribution'
    },
    {
      label: "Plateforme de livraison",
      image: images.badgeLivraison.childImageSharp,
    },
  ]
  return (
    <section className="for-every-actor section-padding">
      <div className="for-every-actor__content-wrapper container">
        <h2 className="heading heading--section-title">
          Pour tous les acteurs de la vente à emporter
        </h2>
        <h3 className="heading heading--section-subtitle">
          La Consigne GreenGo s'adapte à tout type de structure
        </h3>
        <ul className="for-every-actor__actors">
          {ACTORS.map(actor => {
            return (
              <li key={actor.label} style={{ width: '100%'}} >
                <a href={actor.link} className="actor">
                  <div className="actor__badge">
                    <Img {...actor.image} />
                  </div>
                  <h4>{actor.label}</h4>
                  {actor.link && <p className="actor__link">En savoir plus {`>`}</p>}
                </a>
              </li>
            )
          })}
        </ul>
        <Link to="/professionnels" className="link button button--color-pink">
          Nous contacter
        </Link>
      </div>
    </section>
  )
}

export default ForEveryActor
