import React from "react"
import "./BlogPosts.scss"
import { graphql, Link, useStaticQuery } from "gatsby"
import BlogPostsList from "../../BlogPostsList"
import { IBlogPageQuery } from "../../../pages/blog"
import { formatPostsNodesToPostEntries } from "../../../templates/utils"
import { ICategory } from "../../PostEntry"

const BlogPosts: React.FunctionComponent = () => {
  const data: IBlogPageQuery["data"] = useStaticQuery(graphql`
    query GetLatestBlogPosts {
      blogPosts: allMdx(
        limit: 3
        sort: { fields: frontmatter___date, order: DESC }
        filter: { frontmatter: { templateKey: { eq: "BlogPost" } } }
      ) {
        nodes {
          ...BlogPost
        }
      }
      categories: allMdx(
        filter: { frontmatter: { templateKey: { eq: "BlogCategory" } } }
      ) {
        nodes {
          frontmatter {
            slug
            title
          }
        }
      }
    }
  `)
  const allCategories: ICategory[] = data.categories.nodes.map(categoryNode => categoryNode.frontmatter);
  const posts = formatPostsNodesToPostEntries(data.blogPosts.nodes, allCategories);
  return (
    <section className="blogposts section-padding">
      <div className="container">
        <h2 className="heading heading--section-title">
          UNE HISTOIRE QUI SE CONSTRUIT ENSEMBLE
        </h2>
        <h3 className="heading heading--section-subtitle">
          Découvrez nos articles et nos actualités
        </h3>
        <BlogPostsList posts={posts} />
        <Link to="/blog" className="link button button--color-pink">
          Découvrir le blog
        </Link>
      </div>
    </section>
  )
}

export default BlogPosts
