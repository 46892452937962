import React from "react"

import collecte from "../../../images/index/collecte.png"
import phone from "../../../images/index/phone.svg"
import tupperware from "../../../images/index/tupperware.svg"
import StoreButtons from "../../StoreButtons"
import Step from "./Step"

import "./HowItWorks.scss"
import { Link } from "gatsby"

const HowItWorks: React.FunctionComponent = () => {
  return (
    <section className="steps background--pale-turquoise section-padding">
      <div className="container">
        <h2 className="heading heading--section-title">Comment ça marche ?</h2>
        <h3 className="heading heading--section-subtitle">
          Empruntez gratuitement un emballage réutilisable et voyez les poubelles
          s’alléger
        </h3>
        <div className="description">
          <p>
            Téléchargez l’app’ mobile La Consigne GreenGo et empruntez autant
            d’emballages que vous le souhaitez.
          </p>
          <p>
            Ainsi, accédez à un réseau de restaurants et de Franprix partenaires
            dont la proximité facilite le retour des emballages !
          </p>
        </div>
        <div className="steps__items">
          <Step
            number={1}
            image={phone}
            imageWidth={70}
            text="Télécharger l'app' mobile La Consigne GreenGo"
          />
          <Step
            number={2}
            image={tupperware}
            imageWidth={100}
            text="Emprunter un emballage réutilisable chez un commerçant partenaire"
          />
          <Step
            number={3}
            image={collecte}
            imageWidth={120}
            text="Rapporter l’emballage dans l’un des points de collecte du réseau"
          />
        </div>
        <Link
          className="link button button--color-pink"
          to="/nous-trouver"
        >
          Découvrir le réseau
        </Link>
      </div>
    </section>
  )
}

export default HowItWorks
