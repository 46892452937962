import React from "react"
import "./AboveTheFold.scss"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import StoreButtons from "../../StoreButtons"

const AboveTheFold: React.FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    fragment HeroImage on File {
      childImageSharp {
        fluid(
          maxWidth: 2880
          srcSetBreakpoints: [800, 1275, 1440, 1920, 2880]
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      mdx(slug: { eq: "homepage" }) {
        id
        frontmatter {
          templateKey
          header {
            background {
              ...HeroImage
            }
            foreground {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            tagline
            title
            desktopTitle
          }
        }
      }
    }
  `)
  const headerData = data.mdx.frontmatter.header
  return (
    <section className="above-the-fold section-padding">
      <div className="above-the-fold__background-image">
        <Img
          fluid={headerData.background.childImageSharp.fluid}
          style={{ height: "100%" }}
          loading="eager"
          fadeIn={false}
        />
      </div>
      <div className="above-the-fold__app1 container">
        <div className="col-3">
          <div className="above-the-fold__img">
            <Img
              fluid={headerData.foreground.childImageSharp.fluid}
              alt="greengo-smartphone"
              loading="eager"
              fadeIn={false}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="above-the-fold__text">
            <div className="above-the-fold__placeholder"></div>
            <div className="">
              {headerData.desktopTitle ? (
                <h1
                  className="heading heading--1"
                  dangerouslySetInnerHTML={{
                    __html: headerData.desktopTitle.replace(/\n/g, "</br>"),
                  }}
                />
              ) : (
                <h1 className="heading heading--1">{headerData.title}</h1>
              )}
              <p
                className="paragraph paragraph--white paragraph--header"
                dangerouslySetInnerHTML={{
                  __html: headerData.tagline.replace(/\n\n/g, "</br>"),
                }}
              />
            </div>
            <StoreButtons />
          </div>
        </div>
        <div className="col-3"></div>
      </div>
    </section>
  )
}

export default AboveTheFold
