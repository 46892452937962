import React from "react"
import "./BlogPostsList.scss"
import PostEntry, { ICategory, IPostEntry } from "./PostEntry"

interface IBlogPostsList {
  posts: IPostEntry[]
}
const BlogPosts: React.FunctionComponent<IBlogPostsList> = props => {
  return (
    <ul className="blog-posts-list__posts">
      {props.posts.map(postEntry => (
        <li key={postEntry.uri}>
          <PostEntry
            category={postEntry.category}
            date={postEntry.date}
            image={postEntry.image}
            title={postEntry.title}
            uri={postEntry.uri}
          />
        </li>
      ))}
    </ul>
  )
}

export default BlogPosts
