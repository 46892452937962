import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImageFluidProps } from "gatsby-image"
import React from "react"
import LogosSlider, { ILogo } from "./LogosSlider"


interface ITestimonialLogosSlider {
  location: "homepage" | "pro"
}

export interface ILogoNode {
  height: number
  location: "homepage" | "pro"
  title: string
  image: { childImageSharp: GatsbyImageFluidProps | undefined, publicURL: string }
}
const TestimonialLogosSlider: React.FunctionComponent<ITestimonialLogosSlider> = props => {
  const query: {
    mdx: { frontmatter: { logos: ILogoNode[] } }
  } = useStaticQuery(graphql`
    fragment PartnerLogo on File {
      childImageSharp {
        fluid(maxWidth: 150, srcSetBreakpoints: [150]) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      publicURL
    }
    query Logos {
      mdx(slug: { eq: "logos" }) {
        frontmatter {
          logos {
            height
            location
            title
            image {
              ...PartnerLogo
            }
          }
        }
      }
    }
  `)
  const logosData = query.mdx.frontmatter.logos
  const logosForCurrentLocation = logosData.filter(logoData =>
    logoData.location.includes(props.location)
  )
  const formattedLogosData: ILogo[] = logosForCurrentLocation.map(logoData => {
    return {
      alt: `Logo de ${logoData.title}`,
      fluidObject: logoData.image.childImageSharp?.fluid,
      publicURL: logoData.image.publicURL,
      height: logoData.height,
    }
  })
  return <LogosSlider logos={formattedLogosData} />
}

export default TestimonialLogosSlider
