import React from "react"
import TestimonialLogosSlider from '../../TestimonialLogosSlider';

const Testimonials: React.FunctionComponent = () => {
  return (
    <section className="testimonials background--white">
      <section className="slides">
        <TestimonialLogosSlider location="homepage" />
      </section>
    </section>
  )
}

export default Testimonials
