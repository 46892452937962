import relativeDateFactory from 'tiny-relative-date/lib/factory';

// https://github.com/wildlyinaccurate/tiny-relative-date/pull/8 if this is merged, then we can update lib and remove the const below
const fr = {
  justNow: "à l'instant",
  secondsAgo: "il y a {{time}} secondes",
  aMinuteAgo: "il y a une minute",
  minutesAgo: "il y a {{time}} minutes",
  anHourAgo: "il y a une heure",
  hoursAgo: "il y a {{time}} heures",
  aDayAgo: "hier",
  daysAgo: "il y a {{time}} jours",
  aWeekAgo: "il y a une semaine",
  weeksAgo: "il y a {{time}} semaines",
  aMonthAgo: "il y a un mois",
  monthsAgo: "il y a {{time}} mois",
  aYearAgo: "il y a un an",
  yearsAgo: "il y a {{time}} ans",
  overAYearAgo: "il y a plus d'un an",
  secondsFromNow: "dans {{time}} secondes",
  aMinuteFromNow: "dans une minute",
  minutesFromNow: "dans {{time}} minutes",
  anHourFromNow: "dans une heure",
  hoursFromNow: "dans {{time}} heures",
  aDayFromNow: "demain",
  daysFromNow: "dans {{time}} jours",
  aWeekFromNow: "dans une semaine",
  weeksFromNow: "dans {{time}} semaines",
  aMonthFromNow: "dans un mois",
  monthsFromNow: "dans {{time}} mois",
  aYearFromNow: "dans un an",
  yearsFromNow: "dans {{time}} ans",
  overAYearFromNow: "dans plus d'un an",
}

export const relativeDateFormat = (date: Date) => {
  const formatter = relativeDateFactory(fr);
  return formatter(date);
}
