import React from "react"
import "./Step.scss";

interface IStepProps {
  number: number
  text: string
  image: string
  imageWidth?: number
}

const Step: React.FunctionComponent<IStepProps> = props => {
  return (
    <div className={`step step--${props.number}`}>
      <div className="step__number-container">
        <h4 className="step__number">{props.number}</h4>
      </div>
      <p>{props.text}</p>
      <img src={props.image} alt="shop" className="shop__image" width={props.imageWidth} />
    </div>
  )
}

export default Step
