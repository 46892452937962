import { IBlogPageQuery } from "../pages/blog"
import { ICategory, IPostEntry } from "../components/PostEntry"

export const formatPostsNodesToPostEntries = (postsNodes: IBlogPageQuery['data']['blogPosts']['nodes'], allCategories: ICategory[]): IPostEntry[] => {
  const categoryData = (categories: string[]): ICategory[] => {
    return categories.map(categorySlug => {
      const matchingCategory = allCategories.find(
        category => category.slug === categorySlug
      );
      return matchingCategory ? { slug: categorySlug, title: matchingCategory.title} : { slug: 'autres', title: 'Autres'};
    });
  }
  return postsNodes.map(postNode => {
    return {
      image: postNode.frontmatter.image.childImageSharp,
      title: postNode.frontmatter.title,
      category: categoryData(postNode.frontmatter.category),
      date: postNode.frontmatter.date,
      uri: postNode.fields.slug,
    }
  })
}
